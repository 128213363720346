import ownerDocument from 'dom-helpers/ownerDocument';
import getOffset from 'dom-helpers/query/offset';
import getScrollLeft from 'dom-helpers/query/scrollLeft';
import getScrollTop from 'dom-helpers/query/scrollTop';

type Position = {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
};

type Dimensions = {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  scroll?: Position;
};

// Adapted from https://github.com/adobe/react-spectrum/blob/main/packages/@react-aria/overlays/src/calculatePosition.ts#L101-L122
export const getContainerDimensions = (containerNode: HTMLElement): Dimensions => {
  const scroll: Position = {};
  const visualViewport = typeof window !== 'undefined' && window.visualViewport;
  let dimensions: Omit<Dimensions, 'scroll'> = {};

  if (containerNode.tagName === 'BODY') {
    dimensions.width =
      visualViewport?.width ?? (typeof document !== 'undefined' ? document.documentElement.clientWidth : 0);
    dimensions.height =
      visualViewport?.height ?? (typeof document !== 'undefined' ? document.documentElement.clientHeight : 0);

    scroll.top = getScrollTop(ownerDocument(containerNode).documentElement) || getScrollTop(containerNode);
    scroll.left = getScrollLeft(ownerDocument(containerNode).documentElement) || getScrollLeft(containerNode);
  } else {
    dimensions = getOffset(containerNode);

    scroll.top = getScrollTop(containerNode);
    scroll.left = getScrollLeft(containerNode);
  }

  return { ...dimensions, scroll };
};
