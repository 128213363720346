import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { AriaPositionProps, PositionAria, useOverlayPosition as useAriaOverlayPosition } from '@react-aria/overlays';
import useSize from '@react-hook/size';
import { CSSProperties } from 'react';

import { getContainerDimensions } from '../utils/container-dimensions';

export function useOverlayPosition({
  matchTriggerWidth,
  ...props
}: AriaPositionProps & { matchTriggerWidth?: boolean }): PositionAria & { arrowIcon: FontAwesomeIconProps['icon'] } {
  const [triggerWidth] = useSize(props.targetRef);
  const { overlayProps, placement = 'bottom', updatePosition, arrowProps } = useAriaOverlayPosition(props);

  const style: CSSProperties = Object.assign({}, arrowProps.style, { fontSize: 16, lineHeight: 0 });
  let arrowIcon: FontAwesomeIconProps['icon'] = 'caret-up';

  switch (placement) {
    case 'bottom':
      style.top = -10;
      style.marginLeft = -5;
      break;
    case 'top':
      style.bottom = -10;
      style.marginLeft = -5;
      arrowIcon = 'caret-down';
      break;
    case 'left':
      style.right = -6;
      style.marginTop = -8;
      arrowIcon = 'caret-right';
      break;
    case 'right':
      style.left = -5;
      style.marginTop = -8;
      arrowIcon = 'caret-left';
      break;
    default:
      console.warn(
        "Hey, you're using an invalid placement prop! Check out the docs for appropriate usage, or remove it.",
      );
  }

  arrowProps.style = style;

  const overlayStyle: CSSProperties = overlayProps.style || {};
  if (matchTriggerWidth) {
    overlayStyle.width = triggerWidth;
  }

  if (props.shouldFlip && props.offset) {
    // invert axis to get correct CSS property
    const axisMap = {
      top: 'bottom',
      bottom: 'top',
    };
    const axis = axisMap[placement];
    let disableOffset = false;

    if (!props.placement.includes(placement)) {
      // direction is flipped, disable main axis offset
      disableOffset = true;
    } else if (props.targetRef.current?.nodeType) {
      const boundaryElement = props.boundaryElement || (typeof document !== 'undefined' ? document.body : null);
      const boundaryDimensions = getContainerDimensions(boundaryElement);
      const triggerDimensions = getContainerDimensions(props.targetRef.current);
      const overlayTopPost = triggerDimensions.top + props.offset;

      if (boundaryDimensions.scroll.top >= overlayTopPost) {
        disableOffset = true;
      }
    }

    if (disableOffset && typeof overlayStyle[axis] === 'number') {
      overlayStyle[axis] -= props.offset;
    }
  }

  overlayProps.style = overlayStyle;

  return { overlayProps, placement, updatePosition, arrowProps, arrowIcon: ['fas', arrowIcon] };
}
