import * as React from 'react';

export function useShouldCloseOnInteractOutside(isOpen: boolean) {
  return React.useMemo(preventClosingForShortTime, [isOpen]);
}

// returns implementation of shouldCloseOnInteractOutside for making sure that clicking elements outside the modal
// but triggered by modal itself (like select, menu) will not close the modal
// see https://github.com/stoplightio/mosaic/issues/303 for details
function preventClosingForShortTime() {
  let shouldPrevent = false;
  return function shouldCloseOnInteractionOutside(element: HTMLElement) {
    if (isElementAllowedToBeInteractedOutside(element)) {
      shouldPrevent = true;
      setTimeout(() => {
        shouldPrevent = false;
      }, 400);
      return false;
    } else if (shouldPrevent) {
      return false;
    }
    return true;
  };
}

function isElementAllowedToBeInteractedOutside(element: HTMLElement) {
  const popoverParent = element.closest('[data-ispopover="true"]');
  return !!popoverParent;
}
